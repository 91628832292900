import {Navbar, Container, Nav, Image} from "react-bootstrap"
const Footer = () =>{
    return(
        <div>
<footer class="footer mt-auto py-3 bg-dark">
  <div class="container">
    <span class="text-muted"><i>&copy;  Copyright 2023 <a href="https://daaanz.my.id" target="_blank">Dadan Romadhan</a>&nbsp;</i></span>
  </div>
</footer>
        </div>
    )
}
export default Footer